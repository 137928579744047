import React, { useEffect } from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";
import LazyLoad from "react-lazyload";
import $ from "jquery";

const BaneryPage = ({ location: { pathname } }) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (window.innerWidth < 576) {
        $(".banner-col").addClass("hidden-col");
      }
    }
  }, []);

  const loadMoreBaners = () => {
    $(".hidden-col:lt(3)").removeClass("hidden-col");
    if ($(".hidden-col").length <= 0) {
      $("#more-baners-button").hide();
    }
  };
  return (
    <Layout
      seo={{
        title: "Banery statyczne i dynamiczne, reklama graficza",
        description:
          "Wyróżnij się na tle swojej konkurkurencji. Stworzymy dla Ciebie dedykowane banery statyczne, banery dynamiczne oraz banery elastyczne. Reklama graficzna to najlepsza metoda na realizacje sprzedaży, działań wizerunkowych i sprzedażowych. Poznaj nasze portflio i zrealizuj z nami swoją kampanię banerową.",
        href: pathname,
        lang: "pl",
      }}
    >
      <span className="anchor" id="home"></span>
      <div className="subpage-header subpage-header--single-offer">
        <h1 className="subpage-header__title">
          Banery <br /> <span>statyczne i dynamiczne</span>
        </h1>
      </div>

      <div className="single-offer-menu-container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/uslugi/#ppc">
              <div className="back-wrapper">
                <span className="back-wrapper__arrow"></span>
                <p className="back-wrapper__title">Usługi</p>
              </div>
            </Link>
            <p className="nav-title">Specjalizacje:</p>
            <ul>
              <li>
                <Link to="/audyt-kampanii-google-facebook-ads/">
                  Audyt kampanii <br /> Google/Facebook Ads
                </Link>
              </li>
              <li>
                <Link to="/kampanie-googleads-search/">
                  Kampanie Google Ads search
                </Link>
              </li>
              <li>
                <Link to="/kampanie-wideo-na-youtube/">
                  Kampanie Youtube Ads
                </Link>
              </li>
              <li>
                <Link to="/kampanie-w-sieci-reklamowej-gdn/">Kampanie GDN</Link>
              </li>
              <li>
                <Link to="/kampanie-produktowe-product-listing-ads/">
                  Kampanie PLA
                </Link>
              </li>
              <li>
                <Link to="/tiktok-ads/">Kampanie TikTok Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-facebook-ads/">Kampanie Facebook Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-na-instagramie/">
                  Kampanie na Instagramie
                </Link>
              </li>
              <li>
                <Link to="/kampanie-linkedin-ads/">Kampanie LinkedIn</Link>
              </li>
              <li className="current">
                <a href="#home">Banery statyczne i dynamiczne</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <section className="single-offer-section-text-left single-offer-section-text-left--cat-2">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">Wyróżnij się</h2>
                <p className="text-col__desc">
                  Banner blindness to zjawisko polegające na odruchowym
                  ignorowaniu przez użytkowników elementów stron WWW
                  wyglądających jak reklamy. Skupienie uwagi użytkownika nie
                  jest łatwe, dlatego tak bardzo ważne jest, aby nie tylko
                  precyzyjnie dobrać komunikat do wybranej grupy docelowej w
                  celu zainteresowania odbiorcy, ale również zastosować
                  najnowsze, ciekawe formaty reklamowe i wdrożyć ich
                  dywersyfikację.
                  <br />
                  <br />
                  Do dyspozycji reklamodawców są obecnie banery statyczne,
                  banery <span>dynamiczne</span> (tworzone w technologii AMP
                  HTML) oraz banery <span>elastyczne</span> bazujące na zasobach
                  statycznych lub wideo, które automatycznie dostosowują się do
                  powierzchni reklamowej udostępnianej przez witryny
                  internetowe, zwiększając prawdopodobieństwo ich wyświetlenia.
                  Jeżeli prowadzisz sklep internetowy, możliwe będzie również
                  uruchomienie banerów dynamicznych rozumianych jako banery,
                  które tworzą się automatycznie na podstawie zdjęcia oraz ceny
                  produktów, którymi zainteresowany był wcześniej użytkownik.
                </p>
              </div>
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/baners-img-1.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-right single-offer-section-text-right--banery">
        <div className="row">
          <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-5 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/baners-img-2.jpg")}
                  alt=""
                />
              </div>
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Google Display Network
                </h2>
                <p className="text-col__desc">
                  W zależności od branży oraz strategii reklamy kampanie Google
                  Display Network w dalszym ciągu cieszą się dużą popularnością
                  oraz skutecznością. Odpowiednie targetowanie reklamy,
                  dopracowane kreacje graficzne oraz ciekawy komunikat przysłużą
                  się budowaniu świadomości marki oraz zintensyfikowaniu ruchu
                  na stronie. Nieodłącznym elementem większości kampanii będą
                  również kampanie <span>remarketingowe</span>, jako zaplanowany
                  proces lejka sprzedażowego.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-small">
        <div className="row">
          <div className="col-lg-6 offset-lg-4 text-col">
            <h2 className="text-col__title">Remarketing</h2>
            <img
              src={require("../assets/images/baners-img-3.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>
      <section className="single-offer-section-baners-grid">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <h2 className="single-offer-section-baners-grid__title">
              Zobacz nasze przykładowe <br /> realizacje banerów dynamicznych
            </h2>
            <p className="single-offer-section-baners-grid__desc">
              Uwaga! Jeżeli banery nie otwierają się poprawnie, wyłącz AdBlock.
            </p>
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                <LazyLoad once>
                  <div className="baner-container">
                    <iframe
                      src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/gubalowka/index.html"
                      frameBorder="0"
                      width="100%"
                      height="600"
                      title="Rezydencja Gubałówka"
                    ></iframe>
                  </div>
                </LazyLoad>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                <LazyLoad once>
                  <div className="baner-container">
                    <iframe
                      src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/aparthotel_jesien/index.html"
                      frameBorder="0"
                      width="100%"
                      height="600"
                      title="Aparthotel Jesienna Kreacja"
                    ></iframe>
                  </div>
                </LazyLoad>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                <LazyLoad once>
                  <div className="baner-container">
                    <iframe
                      src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/ibis/index.html"
                      frameBorder="0"
                      width="100%"
                      height="600"
                      title="Ibis"
                    ></iframe>
                  </div>
                </LazyLoad>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 banner-col">
                <LazyLoad once>
                  <div className="baner-container">
                    <iframe
                      src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/horizon/index.html"
                      frameBorder="0"
                      width="100%"
                      height="600"
                      title="Hotel Horizon"
                    ></iframe>
                  </div>
                </LazyLoad>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 banner-col">
                <LazyLoad once>
                  <div className="baner-container">
                    <iframe
                      src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/fitizzio/index.html"
                      frameBorder="0"
                      width="100%"
                      height="600"
                      title="Fitizzio"
                    ></iframe>
                  </div>
                </LazyLoad>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 banner-col">
                <LazyLoad once>
                  <div className="baner-container">
                    <iframe
                      src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/ekofrost/index.html"
                      frameBorder="0"
                      width="100%"
                      height="600"
                      title="Ekofrost"
                    ></iframe>
                  </div>
                </LazyLoad>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/adreamux/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="UX Adream"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/alicja/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="Ogród Świateł"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/aparthotel_swieta/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="Aparthotel kreacja świąteczna"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/aparthotel_sylwester/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="Aparthotel kreacja sylwestrowa"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/biala_perla_90/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="Biała Perła 90%"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/biala_perla_inwestuj/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="Biała Perła zainwestuj"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/bogucianka/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="Bogucianka"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/city/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="City"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/czarna_gora_rower/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="Czarna Góra Rowery"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/domki_chorwacja/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="Domki na Chorwacji"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/ehnsa/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="EHNSA"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/golden_gate_point/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="Golden Gate Point"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/infinity/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="Hotel Infinity"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/lake_hill/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="Lake Hill"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/nma/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="Napraw Mi Auto"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/szczawnica/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="Hotel Szczawnica"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/virego/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="Virego"
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 hidden-col last-col">
                <div className="baner-container">
                  <iframe
                    src="https://when.zenx.pl/wp-content/themes/adream/assets/baners/vortune/index.html"
                    frameBorder="0"
                    width="100%"
                    height="600"
                    title="Vortune"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="center-button">
              <button
                className="btn btn-box single-offer-section-baners-grid__button"
                id="more-baners-button"
                onClick={loadMoreBaners}
              >
                załaduj więcej <strong>banerów</strong>
              </button>
            </div>
          </div>
        </div>
      </section>
      <span className="anchor" id="kontakt"></span>
      <section className="single-offer-section-contact">
        <div className="form-wrapper">
          <h2 className="single-offer-section-contact__title mb-40">
            Skontaktuj się z nami! <br />
            Wypełnij <span>formularz</span>
          </h2>
          <ContactFormDark locales={locales["pl"]} messageRequired={false} />
        </div>
        <img
          className="img-fluid single-offer-section-contact__hand"
          src={require("../assets/images/home-contact-hand-right.png")}
          alt=""
        />
      </section>
    </Layout>
  );
};
export default BaneryPage;
